import {
    Toast
} from "vant";
import {
    checkCellphone
} from '@/utils/utils';
import {
    sendVerifyCode
} from '@/api/api'
export const Children = {

    data() {
        return {
            mobile: '',
            btnText: '获取验证码',
            btnDisabled: false,
            seconds: 60,
            code: '',
            project_id: null
        }
    },
    mounted() {
        this.project_id=localStorage.getItem('project_id')
        // 防止安卓软键盘压缩背景
        this.bodyHeight=document.documentElement.clientHeight - 45
        console.log("bodyHeight",this.bodyHeight)
    },
    methods: {
        startTimer() {
            // this.btnDisabled = true;
            this.btnText = this.seconds + 's';
            this.timer = setTimeout(() => {
                this.seconds = this.seconds - 1;
                if (this.seconds <= 0) {
                    clearTimeout(this.timer);
                    this.seconds = 60;
                    this.btnText = '获取验证码';
                    // this.btnDisabled = false;
                } else {
                    this.startTimer();
                }
            }, 1000);
        },
        async getCode() {
            console.log("@login_project_id",this.project_id)
            if (this.btnDisabled) return
            if (!this.mobile) {
                Toast('请输入手机号')
                return false
            } else if (!checkCellphone(this.mobile)) {
                Toast('手机号格式有误')
                return false;
            }
            try {
                let res = await sendVerifyCode({
                    mobile: this.mobile,
                    projectId: this.project_id,
                })
                Toast(res.message)
                if (res.status) {
                    this.startTimer()
                }
            } catch (err) {
                Toast(err.response.data.message)
            }

        }
    }
}