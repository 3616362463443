<template>
  <div class="form-panel" v-if="isShow">
    <van-form class="from" input-align="right">
      <template v-for="(item, index) in list">
        <van-field v-model="item.value" :label="item.label" :required="item.require == 1" :placeholder="item.placeholder ? item.placeholder : (item.label == '科室' ? '请选择' : '请输入') + item.label" rows="1" autosize v-if="item.type == 'text' && item.name != 'other_hospital'" :type="item.limit == 'number' ? 'number' : 'textarea'" :readonly="item.readonly == 1 || item.label == '科室'" @input="onInput($event, item)" @click="onShowTreeSelect(item)" :key="index" />
        <van-field v-model="item.value" :label="item.label" :required="item.require == 1" :placeholder="item.placeholder ? item.placeholder : '请输入' + item.label" rows="2" autosize maxlength="500" v-if="item.type == 'textarea'" :type="item.limit == 'number' ? 'number' : 'textarea'" :readonly="item.readonly == 1" :key="index" />
        <template v-if="item.type == 'select'">
          <template v-if="item.name == 'province_id'"></template>
          <van-field v-else-if="item.name == 'city_id'" readonly label="医院所在地" :required="item.require == 1" v-model="address" @click="(item.readonly == 1) ? '' : (showAddress = true)" :right-icon="(item.readonly == 1) ? '' : 'arrow-down'" rows="1" autosize type="textarea" :key="index" />
          <van-field v-else-if="item.name == 'hospital_id'" readonly label="医院名称" :required="item.require == 1" v-model="hospital" @click="(item.readonly == 1) ? '' : (showHospital = true)" :right-icon="(item.readonly == 1) ? '' : 'arrow-down'" rows="1" autosize type="textarea" :key="index" />
          <div v-else :key="index">
            <van-field readonly :label="item.label" :required="item.require == 1" v-model="item.value" @click="openPopup(index)" :right-icon="(item.readonly == 1) ? '' : 'arrow-down'" rows="1" autosize type="textarea" />
            <van-popup v-model="item.showOption" round position="bottom" :style="{ height: '300px' }" @click-overlay="closePopup(index)">
              <!-- <form action="/">
                <van-search v-model="item.keyword" placeholder="请输入搜索关键词" @search="onSearch(index, item.keyword)" @clear="clearSearch(index, item.keyword)" />
                <van-picker :default-index="findIndex(item.option, item.value)" show-toolbar :columns="getOption(item.option, item.keyword)" value-key="value" :style="{height:'246px'}" @cancel="closePopup(index)" @confirm="confirm(index, $event)" />
              </form> -->
              <van-picker :default-index="findIndex(item.option, item.value)" show-toolbar :columns="getOption(item.option, item.keyword)" value-key="value" @cancel="closePopup(index)" @confirm="confirm(index, $event)">
                <template #title>
                  <van-search class="search_ipt" v-model="item.keyword" input-align="left" shape="round" :placeholder="'搜索' + `${item.label}` + '名称'" @input="onSearch(index, item.keyword)" @clear="clearSearch(index, item.keyword)" />
                </template>
              </van-picker>
            </van-popup>
          </div>
          <!-- 例例生辉二期医院名称选择其他时显示其他输入框 -->
          <van-field v-if="short_name == 'llsheq' && item.name == 'hospital_id' && hospital == '其他'" v-model="other_hospital" label="其他医院" placeholder="请输入其他医院名称">{{ other_hospital }}</van-field>
        </template>
        <van-field :label="item.label" :required="item.require == 1" readonly name="picker" :value="item.value" :placeholder="item.placeholder ? item.placeholder : '点击选择' + item.label" @click="showPick(item, index)" v-if="item.type == 'radio'" right-icon="arrow-down" :key="index" />
        <van-field :label="item.label" :required="item.require == 1" name="checkboxGroup" v-if="item.type == 'checkbox'" :key="index">
          <template #input>
            <van-checkbox-group v-model="item.value" :max="item.select_count">
              <template v-for="(checkItem, checkIndex) in item.options">
                <van-checkbox :name="checkItem" shape="square" style="margin-bottom:5px" :disabled="item.readonly == 1" :key="checkIndex">
                  {{ checkItem }}
                </van-checkbox>
              </template>
            </van-checkbox-group>
          </template>
        </van-field>
        <van-field :label="item.label" :required="item.require == 1" readonly clickable name="datetimePicker" :value="item.value" :placeholder="item.placeholder ? item.placeholder : '点击选择' + item.label" @click="openTime(item, index)" v-if="item.type == 'date'" :key="index" />
        <configure v-if="item.type == 'configure'" :item="item" :key="index"></configure>
        <template v-if="item.type == 'daterange'">
          <van-field :label="item.label" :required="item.require == 1" readonly clickable name="calendar" :value="item.value.time" placeholder="点击选择区间" @click="difPick(item, index)" :key="index" />
          <van-field :label="item.daterange_diff ? item.daterange_diff : item.label" :required="item.require == 1" readonly clickable name="calendar" :value="item.value.day ? item.value.day + '天' : ''" placeholder="__天" :key="index" />
        </template>

        <Upload v-if="item.type == 'file'" :item="item" @getimg="getimg" :record_id="record_id" :key="index"></Upload>
      </template>
    </van-form>

    <div class="btn">
      <van-button round type="info" size="large" class="up" :disabled="disabled" native-type="submit" @click="next">下一步
      </van-button>
    </div>
    <div class="goBack" @click="back">
      <img src="/static/goBack.png" />
    </div>
    <van-popup v-model="timePicker" position="bottom">
      <van-datetime-picker :type="timeType" @confirm="timeConfirm" @cancel="timeCancel" v-model="time" />
    </van-popup>
    <van-popup v-model="showPicker" position="bottom">
      <van-picker show-toolbar :columns="columns" @confirm="pickConfirm" @cancel="pickCancel" />
    </van-popup>
    <van-calendar v-model="showDif" type="range" @confirm="difConfirm" @close="difClose" :minDate="minDate" />
    <van-popup v-model="showAddress" round position="bottom">
      <van-picker show-toolbar :columns="addressArr" @cancel="showAddress = false" @confirm="onConfirm" />
    </van-popup>
    <van-popup v-model="showHospital" round position="bottom">
      <van-picker show-toolbar :columns="hospitalArr" @cancel="showHospital = false" @confirm="onHospital" value-key="name">
        <template #title>
          <div>
            <van-search v-model="hospital_keyvalue" shape="round" placeholder="搜索医院名称" @input="onSearchHospital()" @clear="clearSearchHospital()" />
          </div>
        </template>
      </van-picker>
    </van-popup>
    <van-popup v-model="showTreeSelect" round position="bottom" :style="{ height: '75%' }">
      <div class="toolbar_box">
        <div class="cancel_text" @click="onCancel">取消</div>
        <van-search v-model="departmentValue" @input="onSearchInput" @search="searchDepartment" show-action shape="round" placeholder="请输入搜索科室明细关键词">
          <template #action>
            <div @click="onDepartmentComfirm"><span class="confirm_text">确定</span></div>
          </template>
        </van-search>
      </div>


      <van-tree-select height='calc(100% - 54px)' :items="departmentItems" :active-id.sync="activeId" :main-active-index.sync="activeIndex" @click-nav="clickNav" @click-item="clickItem" />
    </van-popup>

  </div>
</template>

<script>
import Upload from '@/components/upload.vue'
import configure from '@/views/add/configure.vue'
import {
  Toast
} from "vant";
import {
  checkCellphone,
  isIdCardNo,
  checkMail,
  getAge,
  getSexById
} from '@/utils/utils.js'
import {
  provinceCity,
  hospital,
  submit,
  getDepartment, //获取科室
  getCategory, //获取科室明细
  projectHospitals, //获取项目下的医院
} from '@/api/api'
import {
  mapActions,
  mapState,
  mapGetters,
} from 'vuex';
import { Children } from '../views/login/login';

export default {
  components: {
    Upload,
    configure
  },
  name: '',
  props: ['steps', 'arr'],
  computed: {
    list() {
      return this.arr
    },
    ...mapState(['userId', 'basicInformation']),
    ...mapGetters(['sub_id']),
  },
  data() {
    return {
      isShow: false,
      disabled: false,
      showDif: false,
      timePicker: false,
      showPicker: false,
      jobPicker: false,
      columns: [],
      jobcolumns: ["主治医师", "副主任医师", "主任医师"],
      minDate: new Date(2010, 0, 0),
      maxDate: '',
      name: '', //正在操作哪一个
      timeType: 'date',
      record_id: '',
      time: new Date(),
      phase: 1,
      showAddress: false,
      showHospital: false,
      address: '',
      hospital: '',
      addressArr: [],
      hospitalArr: [],
      hospitalArr_copy: [],
      province_id: null,
      city_id: null,
      hospital_id: null,
      hospital_keyvalue: "", //搜索医院的关键字
      departmentValue: '', //科室关键字
      departmentMain: '', //科室大类
      departmentDetail: '', //科室明细
      departmentItems: [],  //科室
      departmentItems_copy: [],
      activeId: 0,  //科室明细id
      activeIndex: 0, //大科id
      showTreeSelect: false,
      project_id: null, //项目id
      other_hospital: "", //其他医院
      short_name: '',
    }
  },
  mounted() {
    console.log("steps", this.steps)
    console.log("basicInformation", this.basicInformation)
    console.log("sub_id", this.sub_id)
    this.project_id = this.basicInformation.projects[0].id
    this.short_name = this.basicInformation.projects[0].short_name
    this.record_id = this.$route.query.record_id ? this.$route.query.record_id : ''
    this.phase = this.$route.query.phase ? this.$route.query.phase : 1
    this.init()
    this.getData() //获取科室、科室明细、职务数据
  },
  methods: {
    async getData() {
      // 获取大科
      await getDepartment({
        params: {
          project_id: this.project_id
        }
      }).then(res => {
        console.log("获取科室：", res)
        let index = 0
        for (let key in res) {
          console.log(res[key], key)
          let item = {}
          item.text = res[key].value
          item.id = res[key].id
          item.children = []
          index++
          this.departmentItems.push(item)
        }
        console.log("this.departmentItems", this.departmentItems)
      })
      for (let key in this.departmentItems) {
        let child_index = 0
        // 获取大科对应的科室明细
        await getCategory({
          params: {
            project_id: this.project_id,
            department_id: this.departmentItems[key].id,
          }
        }).then(res => {
          let children = []
          for (let i in res) {
            let child_item = {}
            child_item.id = child_index
            child_item.text = res[i]
            children.push(child_item)
            child_index++
          }
          this.departmentItems[key].children = children
        })
      }
      this.departmentItems_copy = this.departmentItems
      console.log("this.departmentItems", this.departmentItems)
    },
    // 点击右侧导航时触发
    clickItem(data) {
      console.log("data右", data)
    },
    // 点击左侧导航时触发
    clickNav(index) {
      console.log("index左", index)
      this.activeId = 0
    },
    // 点击取消清除所选科室
    onCancel() {
      this.showTreeSelect = false
      for (let key in this.list) {
        if (this.list[key].label == '科室') {
          this.list[key].value = ""
        }
      }
    },
    onSearchInput(searchValue) {
      if (searchValue == '') {
        this.departmentItems = this.departmentItems_copy
      }
    },
    // 搜索科室
    searchDepartment(searchValue) {
      let found_list = []
      let index = 0
      for (let i in this.departmentItems) {
        let item = {}
        if (this.departmentItems[i]?.children) {
          let children = []
          let child_index = 0
          for (let j in this.departmentItems[i].children) {
            let child_item = {}
            if (this.departmentItems[i].children[j].text.indexOf(searchValue) != -1) {
              child_item.id = child_index
              child_item.text = this.departmentItems[i].children[j].text
              child_index++
              children.push(child_item)
            }
          }
          if (children.length > 0) {
            item.id = index
            item.text = this.departmentItems[i].text
            item.children = children
            found_list.push(item)
            index++
          }
        }
      }
      this.departmentItems = found_list
      this.activeId = 0  //科室明细id
      this.activeIndex = 0 //大科id
    },
    // 点击确认
    onDepartmentComfirm() {
      this.showTreeSelect = false
      this.departmentMain = this.departmentItems[this.activeIndex].text
      if (this.departmentItems[this.activeIndex].children) {
        this.departmentDetail = this.departmentItems[this.activeIndex]?.children[this.activeId]?.text
      } else {
        this.departmentDetail = ''
      }
      for (let key in this.list) {
        if (this.list[key].label == '科室') {
          this.list[key].value = this.departmentDetail ? this.departmentMain + '/' + this.departmentDetail : this.departmentMain
        }
      }
    },
    // 显示分类选择
    onShowTreeSelect(item) {
      if (item.label == '科室') {
        this.activeId = 0  //科室明细id
        this.activeIndex = 0 //大科id
        this.showTreeSelect = true
        this.departmentMain = this.departmentItems[0]?.text  //默认大科
        this.departmentDetail = this.departmentItems[0]?.children[0]?.text //默认科室明细
      }
    },
    onSearchHospital() {
      if (this.hospital_keyvalue) {
        let newSearch = [];//新数组
        console.log("this.hospitalArr_copy", this.hospitalArr_copy)
        newSearch = this.hospitalArr_copy.filter((ele) => {
          if (ele.name.indexOf(this.hospital_keyvalue) >= 0) {
            return ele;
          }
        })
        this.hospitalArr = newSearch;
      } else {
        this.hospitalArr = this.hospitalArr_copy
      }
    },
    clearSearchHospital() {
      this.hospitalArr = this.hospitalArr_copy
    },
    async init() {
      for (let i in this.list) {
        let ele = this.list[i]
        if (ele.type == 'select') {
          if (ele.name == 'province_id') {
            this.province_id = ele.value
          } else if (ele.name == 'city_id') {
            this.city_id = ele.value
          } else if (ele.name == 'hospital_id') {
            this.hospital_id = ele.value
          }
          if (ele.hasOwnProperty('option')) {
            ele.showOption = false
            ele.keyword = ''
          }
        }
        if (this.short_name == 'llsheq' && ele.name == 'other_hospital') { //例例生辉二期其他医院字段回显
          this.other_hospital = ele.value
        }
      }
      console.log('init', this.list)
      await this.getAddress()
      if (this.province_id && this.city_id) {
        await this.getHospital()
      }
      this.isShow = true
    },
    async getAddress() {
      let pname = '',
        cname = '',
        hname = '';
      this.addressArr = await provinceCity()
      for (let j in this.addressArr) {
        let elej = this.addressArr[j]
        if (elej.id == this.province_id) {
          pname = elej.text
          for (let k in elej.children) {
            let elek = elej.children[k]
            if (elek.id == this.city_id) {
              cname = elek.text
            }
          }
        }
      }
      this.address = pname && cname ? pname + '/' + cname : ''
    },
    async getHospital() {
      if( ['llsheq','nyfzeq'].includes(this.short_name)){ //例例生辉二期项目医院名称获取接口调用
        await projectHospitals({
          params: {
            province_id: this.province_id,
            city_id: this.city_id,
            subproject_id: this.sub_id
          }
        }).then(res => {
          this.hospitalArr = res
          this.hospitalArr_copy = res
          if (this.hospital_id) {
            for (let i in this.hospitalArr) {
              let ele = this.hospitalArr[i]
              if (ele.id == this.hospital_id) {
                this.hospital = ele.name
              }
            }
          } else {
            if (this.short_name == 'llsheq') { //例例生辉二期选择其他医院时hospital_id=0
              for (let i in this.hospitalArr) {
                let ele = this.hospitalArr[i]
                if (ele.id == this.hospital_id) {
                  this.hospital = ele.name
                }
              }
            }
          }
        })
      } else {
        let { items } = await hospital({  //其他项目医院名称获取接口调用
          params: {
            province_id: this.province_id,
            city_id: this.city_id
          }
        })
        this.hospitalArr = items
        this.hospitalArr_copy = items
        if (this.hospital_id) {
          for (let i in this.hospitalArr) {
            let ele = this.hospitalArr[i]
            if (ele.id == this.hospital_id) {
              this.hospital = ele.name
            }
          }
        }
      }


    },
    confirm(index, e) {
      let item = this.list[index]
      item.value = e.value
      console.log('confirm', e)
      this.closePopup(index)
    },
    openPopup(index) {
      let item = this.list[index]
      if (item.readonly == 1) {
        return
      }
      item.showOption = true
      this.$forceUpdate()
    },
    closePopup(index) {
      let item = this.list[index]
      item.showOption = false
      this.$forceUpdate()
    },
    findIndex(arr, val) {
      for (let i in arr) {
        let ele = arr[i]
        if (ele.name == ele.val) {
          // console.log('findIndex', i)
          this.$forceUpdate
          return i
        }
      }
    },
    async onSearch(index, val) {
      let item = this.list[index]
      item.keyword = val
      this.$forceUpdate()
    },
    clearSearch(index, val) {
      let item = this.list[index]
      item.keyword = ''
      this.$forceUpdate()
    },
    getOption(list, val) {
      let arr = list.filter(item => {
        if (item.value) {
          return item.value.indexOf(val) != -1
        } else if (item.name) {
          return item.name.indexOf(val) != -1
        }
      })
      // console.log('getOption', arr)
      return arr
    },
    onConfirm(value, index) {
      let provinceIndex = index[0]
      let cityIndex = index[1]
      this.showAddress = false
      if ((value[0] + '/' + value[1]) != this.address) {
        this.hospital = ''
        this.hospital_id = ''
      }
      this.address = value[0] + '/' + value[1]
      this.province_id = this.addressArr[provinceIndex].id
      this.city_id = this.addressArr[provinceIndex].children[cityIndex].id
      this.getHospital()
      for (let i in this.list) {
        let ele = this.list[i]
        if (ele.name == 'province_id') {
          ele.value = this.province_id
        } else if (ele.name == 'city_id') {
          ele.value = this.city_id
        } else if (ele.name == 'hospital_id') {
          ele.value = this.hospital_id
        }
      }
      if (this.short_name == 'llsheq') {  //例例生辉二期切换省市时初始化医院名称和其他医院字段
        this.hospital = ""
        this.hospital_id = null
        this.other_hospital = ""
      }
    },
    onHospital(value, index) {
      console.log("onHospital", value, index)
      this.showHospital = false
      this.hospital = value.name
      this.hospital_id = value.id
      for (let i in this.list) {
        let ele = this.list[i]
        if (ele.name == 'hospital_id') {
          ele.value = this.hospital_id
        }
      }
    },
    formCheck(value) {
      if (value.length <= 0) return 'error'
      for (let i in value) {
        let ele = value[i]
        console.log("ele", ele)
        if (ele.require) {
          if (ele.type == 'checkbox' && ele.type == 'file') {
            if (ele.value.length) {
              Toast('请完善信息')
              return 'error'
            }
          } else {
            if (!ele.value) {
              Toast('请完善信息')
              return 'error'
            } else {
              console.log(ele, 'eleeleeleele');
              // !isIdCardNo(ele.value)
              if (ele.name == 'idsn' && !ele.value) {
                Toast('请输入正确的身份证号')
                return 'error'
              } else if ((ele.name == 'mobile' || ele.name == 'contract_mobile') && !checkCellphone(ele.value)) {
                Toast('请输入正确的手机号')
                return 'error'
              } else if (ele.name == 'email' && !checkMail(ele.value)) {
                Toast('请输入正确的邮箱')
                return 'error'
              }
            }
          }
        } else {
          if (ele.name == 'emergency_contact_mobile' && ele.value && !checkCellphone(ele.value)) {
            Toast('请输入正确的手机号')
            return 'error'
          }
          if (this.short_name == 'llsheq' && ele.name == 'hospital_id') { //例例生辉二期医院名称前端做必填拦截
            console.log("医院名称不能为空", ele)
            if (!ele.value && this.hospital != '其他') {
              Toast('医院名称不能为空')
              return 'error'
            }
          }
          // if(this.short_name == 'llsheq' && this.hospital == '其他' && this.other_hospital == "" && this.steps == 0){ //例例生辉二期医院名称选择其他时前端对其他输入框做必填拦截
          //   Toast('请填写其他医院名称')
          //   return 'error'
          // }
        }
      }
      if (this.short_name == 'llsheq' && this.hospital == '其他' && this.other_hospital == "") { //例例生辉二期医院名称选择其他时前端对其他输入框做必填拦截
        Toast('请填写其他医院名称')
        return 'error'
      }
    },
    next() {
      this.disabled = true
      if (this.formCheck(this.list) != 'error') {
        if (this.short_name == 'llsheq') { // 例例生辉二期传入其他医院名称字段other_hospital
          console.log("this.hospital", this.hospital)
          if (this.hospital != '其他') {
            this.other_hospital = ""
          }
          this.$emit('confirm', this.list, this.other_hospital)
        } else {
          this.$emit('confirm', this.list)
        }
      } else {
        this.disabled = false
      }
      return;
      // let data = []
      // let list = this.list
      // for (let i in list) {
      //   let input = list[i].input
      //   for (let j in input) {
      //     if (input[j].require == 1) {
      //       if (input[j].type == 'checkbox' || input[j].type == 'file') {
      //         if (input[j].value.length <= 0) {
      //           Toast('请完善信息')
      //           return false
      //         }
      //       } else {
      //         if (!input[j].value) {
      //           Toast('请完善信息')
      //           return false
      //         } else {
      //           if (input[j].limit == 'idsn' && (!isIdCardNo(input[j].value))) {
      //             Toast('请输入正确的身份证号')
      //             return false
      //           } else if (input[j].limit == 'mobile' && (!checkCellphone(input[j].value))) {
      //             Toast('请输入正确的手机号')
      //             return false
      //           }
      //         }
      //       }
      //     }
      //     let req = {
      //       value: input[j].value,
      //       name: input[j].name
      //     }
      //     data.push(req)
      //   }
      // }
      // this.disabled = true
      // if (this.steps == 0) {
      //   if (this.$route.query.phase && this.$route.query.phase > 1) {
      //     this.record_id = ''
      //   }
      // }
      // submit({
      //   doctor_id: this.userId,
      //   phase: this.phase,
      //   subscheme_id: this.$route.query.subscheme_id,
      //   scheme_id: this.$route.query.scheme_id,
      //   data: data,
      //   step: this.steps,
      //   record_id: this.record_id,
      //   patient_id: this.phase == 1 ? '' : this.$route.query.patient_id
      // }).then(res => {
      //   this.disabled = false
      //   let data = {
      //     record_id: res.record.id,
      //     type: 'up'
      //   }
      //   this.record_id = res.record.id
      //   this.$emit('step', data)
      // }).catch(err => {
      //   this.disabled = false
      //   Toast(err.response.data.message)
      // })
    },
    onInput(event, item) {
      if (item.name == 'idsn' && event.length == 18) {
        let input = this.list[0].input
        let index = input.findIndex(ele => ele.name == 'gender')
        let sex = getSexById(event)
        if (index != -1) this.list[0].input[index].value = sex
        let age = getAge(event)
        index = input.findIndex(ele => ele.name == 'age')
        if (index != -1) this.list[0].input[index].value = age
      }
    },
    getimg() {

    },
    back() {
      this.$emit('step', 'back')
    },
    afterRead(file) {
      console.log(file, 'file')
    },
    difClose() {
      this.index = null
    },
    formatDate(date) {
      return `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`;
    },
    difPick(item, index) {
      if (item.readonly == 1) return false
      this.name = item.name
      this.showDif = true
    },
    difConfirm(date) {
      const [start, end] = date;
      this.showDif = false;
      for (let i in this.list) {
        let input = this.list[i].input
        let index = input.findIndex(ele => ele.name == this.name)
        if (index != -1) {
          this.list[i].input[index].value = {
            time: `${this.formatDate(start)} - ${this.formatDate(end)}`,
            day: new Date(end - start) / (1000 * 60 * 60 * 24) + 1
          }
        }

      }
      this.index = null
    },
    openTime(item, index) {
      if (item.readonly == 1) return false
      this.timePicker = true
      if (item.date_precision == 'month') {
        this.timeType = 'year-month'
      } else {
        this.timeType = 'date'
      }
      this.name = item.name
    },
    timeCancel() {
      this.name = ''
      this.timePicker = false
    },
    timeConfirm(value) {
      const d = new Date(value)
      let time = ''
      if (this.timeType == 'date') {
        time = d.getFullYear() + '-' + (d.getMonth() + 1) + '-' + d.getDate()
      } else {
        time = d.getFullYear() + '-' + (d.getMonth() + 1)
      }
      // console.log(time, 'time')
      for (let i in this.list) {
        let input = this.list[i].input
        let index = input.findIndex(ele => ele.name == this.name)
        if (index != -1) {
          this.list[i].input[index].value = time
        }

      }
      this.timePicker = false
      this.name = ''

    },
    showPick(item, index) {
      if (item.readonly == 1) return false
      // console.log(item, index)
      this.columns = item.options
      this.showPicker = true
      this.name = item.name
      console.log(this.name)
    },
    pickConfirm(value) {
      for (let i in this.list) {
        let input = this.list[i].input
        let index = input.findIndex(ele => ele.name == this.name)
        if (index != -1) {
          this.list[i].input[index].value = value
        }
      }
      this.name = ''
      this.time = ''
      this.showPicker = false
    },
    pickCancel() {
      this.timePicker = false
      this.name = ''
    },
  }
}
</script>

<style lang="scss" scoped>
.form-panel {
  // padding: 0 11px;
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
  box-sizing: border-box;
  margin-bottom: 70px;

  ::v-deep .btn {
    position: absolute;
  }

  .search_ipt {
    width: 100%;
    padding: 10px 0;
  }

  ::v-deep .van-picker__cancel,
  ::v-deep .van-picker__confirm {
    word-break: keep-all !important;
  }

  ::v-deep .van-picker__confirm {
    color: #4B99FF;
  }

  .toolbar_box {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .van-search {
      flex-grow: 1;
    }

    .cancel_text {
      color: #969799;
      font-size: 14px;
      margin-left: 8px;
      cursor: pointer;
    }

    .confirm_text {
      color: #4B99FF;
      margin-left: 2px;
    }
  }

  .van-tree-select__item--active {
    color: #4B99FF;
  }

  .van-sidebar-item--select::before {
    background-color: #4B99FF;
  }

  .title {
    padding-left: 11px;
    box-sizing: border-box;
    font-size: 15px;
    color: #232323;
    margin-bottom: 13px;
    text-align: left;
  }

  .from {
    margin-bottom: 30px;
  }
}
</style>